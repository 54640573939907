<template>
    <div>
        <MeasurementItem
            v-if="externalVoltage"
            :title="externalVoltage.title"
            :datetime="externalVoltage.lastUpdated"
            :link-to="externalVoltage.link"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.external_voltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ externalVoltage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="odometer"
            :title="odometer.title"
            :datetime="odometer.lastUpdated"
            :link-to="odometer.link"
        >
            <template #icon>
                <DistanceIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.odometer') }}:

                <h3 class="sensor-data-odometer">
                    {{ odometer.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="fuelLevel"
            :title="fuelLevel.title"
            :datetime="fuelLevel.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: fuelLevel.key,
                },
            }"
        >
            <template #icon>
                <FuelCanIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.fuel_level') }}:

                <h3 class="sensor-data-percentage">
                    {{ fuelLevel.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="evBatteryPercentage"
            :title="evBatteryPercentage.title"
            :datetime="evBatteryPercentage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: evBatteryPercentage.key,
                },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="evBatteryPercentage.value"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.ev_battery_percentage') }}:

                <h3 class="sensor-data-percentage">
                    {{ evBatteryPercentage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="evBatteryHealth"
            :title="evBatteryHealth.title"
            :datetime="evBatteryHealth.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: evBatteryHealth.key,
                },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="evBatteryHealth.value"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.ev_battery_health') }}:

                <h3 class="sensor-data-percentage">
                    {{ evBatteryHealth.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="evChargingState"
            :title="evChargingState.title"
            :datetime="evChargingState.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: evChargingState.key,
                    labelPair: 'operation',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="evChargingState.value ? 'green' : 'red'"
                />
            </template>

            <template #content>
                <h3 class="measurement-value">
                    {{
                        evChargingState.value
                            ? $t('charging')
                            : $t('chargingNot')
                    }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="fuelConsumption"
            :title="fuelConsumption.title"
            :datetime="fuelConsumption.lastUpdated"
            :link-to="fuelConsumption.link"
        >
            <template #icon>
                <FuelCanIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.fuel_rate') }}:

                <h3 class="sensor-data-lp100km">
                    {{ fuelConsumption.value }}
                </h3>
            </template>
        </MeasurementItem>

        <BatteryMeasurementsFragment v-bind="$props" />

        <MeasurementItem
            v-if="dtcCodes"
            :title="dtcCodes.title"
            :datetime="dtcCodes.lastUpdated"
            @click="isErrorBlockVisible = !isErrorBlockVisible"
        >
            <template #icon>
                <WarningIcon width="24" height="24" color="black" />
            </template>

            <template #content>
                {{ $t('errors') }}:

                <h3>
                    {{ dtcCodes.value }}
                </h3>
            </template>

            <template #actionicon>
                <ArrowDownIcon
                    width="12"
                    height="12"
                    class="arrow-icon"
                    :class="{ 'arrow-icon--rotated': isErrorBlockVisible }"
                />
            </template>
        </MeasurementItem>

        <div v-if="isErrorBlockVisible" class="l-padded">
            <p v-for="(item, i) in dtcCodeList" :key="i">
                <strong>{{ item.code }}.</strong> {{ item.description }}.
            </p>
        </div>
    </div>
</template>

<script>
import { measurementHelper } from '../utils'
import ArrowDownIcon from './icons/ArrowDownIcon'
import BatteryIcon from './icons/BatteryIcon'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DistanceIcon from './icons/DistanceIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import FlashIcon from './icons/FlashIcon'
import FuelCanIcon from './icons/FuelCanIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import WarningIcon from './icons/WarningIcon'

export default {
    name: 'CarMeasurement',
    components: {
        ArrowDownIcon,
        BatteryIcon,
        BatteryMeasurementsFragment,
        DistanceIcon,
        FilledCircleIcon,
        FlashIcon,
        FuelCanIcon,
        MeasurementItem,
        WarningIcon,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            isErrorBlockVisible: false,
        }
    },
    computed: {
        evBatteryHealth() {
            return this.getMeasurementItem('ev_battery_health')
        },
        evBatteryPercentage() {
            return this.getMeasurementItem('ev_battery_percentage')
        },
        evChargingState() {
            return this.getMeasurementItem('ev_charging_state')
        },
        dtcCodeList() {
            return (
                this.tracker.asset_details.sensor_data.dtc_code_list?.value ||
                []
            )
        },
        dtcCodes() {
            const item = this.getMeasurementItem('dtc_codes')
            return item?.value ? item : null
        },
        externalVoltage() {
            return this.getMeasurementItem('external_voltage')
        },
        fuelLevel() {
            return this.getMeasurementItem('fuel_level')
        },
        fuelConsumption() {
            return this.getMeasurementItem('fuel_consumption')
        },
        odometer() {
            const item =
                this.getMeasurementItem('tachograph_odometer') ||
                this.getMeasurementItem('odometer')
            if (item) {
                item.value = measurementHelper.converters.odometer(item.value)
            }
            return item
        },
        speed() {
            return this.getMeasurementItem('speed')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "charging": "Charging",
        "chargingNot": "Not charging",
        "errors": "DTC Codes"
    },
    "de": {
        "charging": "Aufladend",
        "chargingNot": "Nicht aufladend",
        "errors": "DTC Codes"
    },
    "fr": {
        "charging": "Chargement",
        "chargingNot": "Pas de charge",
        "errors": "DTC Codes"
    },
    "it": {
        "charging": "Ricarica",
        "chargingNot": "Non in carica",
        "errors": "DTC Codes"
    }
}
</i18n>

<style lang="scss" scoped>
.arrow-icon {
    margin: 0 4px;
    transition: transform 0.2s;

    &--rotated {
        transform: rotate(0.5turn);
    }
}

.measurement-value {
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 20px;
}

.green {
    color: $color-green;
}

.red {
    color: $color-red;
}
</style>
