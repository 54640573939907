<template>
    <div>
        <MeasurementItem
            v-if="adBlueLevel"
            :title="adBlueLevel.title"
            :datetime="adBlueLevel.lastUpdated"
            :link-to="adBlueLevel.link"
        >
            <template #icon>
                <FuelCanIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.adblue_level') }}:

                <h3 class="sensor-data-percentage">
                    {{ adBlueLevel.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="fuelConsumption"
            :title="fuelConsumption.title"
            :datetime="fuelConsumption.lastUpdated"
            :link-to="fuelConsumption.link"
        >
            <template #icon>
                <FuelCanIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.fuel_rate') }}:

                <h3 class="sensor-data-lp100km">
                    {{ fuelConsumption.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="evBatteryPercentage"
            :title="evBatteryPercentage.title"
            :datetime="evBatteryPercentage.lastUpdated"
            :link-to="evBatteryPercentage.link"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="evBatteryPercentage.value"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.ev_battery_percentage') }}:

                <h3 class="sensor-data-percentage">
                    {{ evBatteryPercentage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="evBatteryHealth"
            :title="evBatteryHealth.title"
            :datetime="evBatteryHealth.lastUpdated"
            :link-to="vBatteryHealth.link"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="evBatteryHealth.value"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.ev_battery_health') }}:

                <h3 class="sensor-data-percentage">
                    {{ evBatteryHealth.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="evChargingState"
            :title="evChargingState.title"
            :datetime="evChargingState.lastUpdated"
            :link-to="evChargingState.link"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="evChargingState.value ? 'green' : 'red'"
                />
            </template>

            <template #content>
                <h3 class="measurement-value">
                    {{
                        evChargingState.value
                            ? $t('charging')
                            : $t('chargingNot')
                    }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import FuelCanIcon from './icons/FuelCanIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'TruckMeasurement',
    components: {
        BatteryIcon,
        FilledCircleIcon,
        FuelCanIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        adBlueLevel() {
            return this.getMeasurementItem('adblue_level')
        },
        evBatteryHealth() {
            return this.getMeasurementItem('ev_battery_health')
        },
        evBatteryPercentage() {
            return this.getMeasurementItem('ev_battery_percentage')
        },
        evChargingState() {
            return this.getMeasurementItem('ev_charging_state')
        },
        fuelConsumption() {
            return this.getMeasurementItem('fuel_consumption')
        },
        speed() {
            return this.getMeasurementItem('speed')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "charging": "Charging",
        "chargingNot": "Not charging"
    },
    "de": {
        "charging": "Aufladend",
        "chargingNot": "Nicht aufladend"
    },
    "fr": {
        "charging": "Chargement",
        "chargingNot": "Pas de charge"
    },
    "it": {
        "charging": "Ricarica",
        "chargingNot": "Non in carica"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-value {
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 20px;
}

.green {
    color: $color-green;
}

.red {
    color: $color-red;
}
</style>
